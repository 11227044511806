const messages = {
  header: 'Ernährung',
  benefitsAlt: 'Zwei Müslischalen auf einem Tisch',
  title1:
    'Wenn Ernährung und Bewegung im Einklang sind, ist es auch dein Körper',
  paragraph1:
    'Vor lauter Diät-Tipps, Superfoods und Clean-Eating-Trends weiss man manchmal gar nicht mehr was man überhaupt noch essen darf. Es gibt so viele interne und externe Faktoren, die es unmöglich machen, eine Lösung für alle zu haben.',
  title2: 'Gerne helfe ich dir weiter',
  paragraph2:
    'Nach einer detaillierten Analyse deines Ernährungsverhaltens erarbeiten wir gemeinsam die richtige Vorgehensweise für dein Ziel. Dabei werde ich all deine Vorlieben und Bedürfnisse berücksichtigen. Ich stelle dir einen individuellen Ernährungsplan zusammen, coache dich bei der Umstellung deiner Ernährungsgewohnheiten und überwache deine Fortschritte in regelmässigen Abständen.',
  title3: 'Schritt für Schritt',
  paragraph3:
    'Wir stellen deine Ernährung systematisch und schrittweise, ganz ohne Hungern und Verbote, auf eine dauerhaft gesündere Ernährungsweise um. In unserem Ernährungscoaching zeig ich dir ganz genau, wie du dich ernähren musst, um deinen Körper optimal mit allen wichtigen Nährstoffen zu versorgen.\n\nEine gesunde Ernährung ist entscheidend für unser Wohlbefinden, unsere Gesundheit und unsere Energie. Wusstest du, dass beim Erreichen von Fitnesszielen und beim Abnehmen das wichtigste die Ernährung ist? Wenn du Ernährung und Bewegung kombinierst, erhältst du die besten Ergebnisse!',
  benefit1: 'Gib deinem Körper was er braucht',
  benefit2: 'Gesunde Ernährung ist individuell',
  benefit3: 'Gewohnheiten erkennen und verändern',
  benefit4: 'Mehr Power in allen Lebenslagen',
}

export default messages
