import styled from 'styled-components'

import { media } from '~styles/mediaQuery'
import { fluidSpacingFunc } from '~styles/helpers'

const Benefits = styled.section`
  position: relative;
  display: grid;
  ${fluidSpacingFunc([{ property: 'grid-gap', values: [5] }])};
  ${fluidSpacingFunc([{ property: 'padding', values: [12, 5] }])};
  ${media.mq1`
    grid-template-rows: repeat(4, 1fr);
    justify-content: center;
  `};
  ${media.mq23`
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    justify-content: center;
  `};
  ${media.mq4`
    grid-template-columns: repeat(4, 1fr);
  `};
`

export default Benefits
