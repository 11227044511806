import React from 'react'
import PropTypes from 'prop-types'

import Layout from '~components/Layout'
import Typography from '~components/Typography'
import { NAVIGATION } from '~utils/constants'

import messages from './messages'
import Content from './Content'
import Header from './Header'
import Benefits from './Benefits'
import Benefit from './Benefit'
import BenefitWrapper from './BenefitWrapper'
import Img from './Img'

const metaTags = {
  title: 'Zur perfekten Ernährung',
  description:
    'Mit der richtigen Ernährung zum Ziel kommen. Sporternährung, Abnehmen. Mit mir erreichst du die besten Ergebnisse.',
  slug: NAVIGATION.SERVICE.NUTRITION,
  image: {
    url: 'PageServiceNutrition/HeroServiceNutrition_01--large',
    alt: 'Teller mit gesundem Essen auf einem Tisch',
  },
}

const hero = {
  pictureData: {
    path: 'PageServiceNutrition',
    alt: 'Teller mit gesundem Essen auf einem Tisch',
    fallback: {
      name: 'HeroServiceNutrition_01--large',
      placeholder:
        'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzNzUxIDE2MDgiPjxmaWx0ZXIgaWQ9ImIiPjxmZUdhdXNzaWFuQmx1ciBzdGREZXZpYXRpb249IjEyIiAvPjwvZmlsdGVyPjxwYXRoIGZpbGw9IiM1MDU5NDEiIGQ9Ik0wIDBoMzc1MXYxNTk3SDB6Ii8+PGcgZmlsdGVyPSJ1cmwoI2IpIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg3LjMgNy4zKSBzY2FsZSgxNC42NTIzNCkiIGZpbGwtb3BhY2l0eT0iLjUiPjxlbGxpcHNlIGZpbGw9IiMwMDAwMmMiIGN4PSIyMjYiIGN5PSIyNCIgcng9IjMwIiByeT0iMTAzIi8+PGVsbGlwc2UgZmlsbD0iI2E4ZDZmZiIgY3g9IjE4MyIgY3k9IjUwIiByeD0iMTUiIHJ5PSIzMyIvPjxlbGxpcHNlIGZpbGw9IiNmOGJhODciIHJ4PSIxIiByeT0iMSIgdHJhbnNmb3JtPSJtYXRyaXgoLTQuNDMxMSAyNC45MzI0MyAtNDcuNTMyNzQgLTguNDQ3NzIgMzkgMTA4KSIvPjxlbGxpcHNlIGZpbGw9IiMwMDE2MDgiIHJ4PSIxIiByeT0iMSIgdHJhbnNmb3JtPSJtYXRyaXgoMTcuMTYzMTUgLTEwLjQ0NDUzIDI2Ljg3OTk1IDQ0LjE3MDkzIDY3LjIgNTQuNikiLz48ZWxsaXBzZSBmaWxsPSIjZWFjMDkxIiBjeD0iMTQiIGN5PSI0IiByeD0iODQiIHJ5PSIxMyIvPjxlbGxpcHNlIGZpbGw9IiNlYmE5MTkiIGN4PSIxNyIgY3k9IjQ4IiByeD0iMjMiIHJ5PSIyNiIvPjxlbGxpcHNlIGZpbGw9IiNkOTljMWQiIHJ4PSIxIiByeT0iMSIgdHJhbnNmb3JtPSJyb3RhdGUoLTExOS4zIDcxLjcgLTQyKSBzY2FsZSg0MC4xNTUyNCAzMC4xMDc3OSkiLz48ZWxsaXBzZSBmaWxsPSIjZDEzZTFlIiByeD0iMSIgcnk9IjEiIHRyYW5zZm9ybT0icm90YXRlKDYuMyAtNjgzLjIgMTM0NS43KSBzY2FsZSgyNi45MjY5IDE1LjM3OTYxKSIvPjwvZz48L3N2Zz4=',
    },
    breakpoints: {
      breakpointSmall: {
        name: 'HeroServiceNutrition_01--small',
        placeholder:
          'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzNzM0IDI4MDEiPjxmaWx0ZXIgaWQ9ImIiPjxmZUdhdXNzaWFuQmx1ciBzdGREZXZpYXRpb249IjEyIiAvPjwvZmlsdGVyPjxwYXRoIGZpbGw9IiM0ZjViNDUiIGQ9Ik0wIDBoMzczNHYyODAwSDB6Ii8+PGcgZmlsdGVyPSJ1cmwoI2IpIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg3LjMgNy4zKSBzY2FsZSgxNC41ODU5NCkiIGZpbGwtb3BhY2l0eT0iLjUiPjxlbGxpcHNlIGZpbGw9IiNlNmY5ZmIiIHJ4PSIxIiByeT0iMSIgdHJhbnNmb3JtPSJyb3RhdGUoLTEwNi4yIDIzLjcgMTQuNikgc2NhbGUoNDIuNDUxMjQgNTEuMzE1MTkpIi8+PGVsbGlwc2UgZmlsbD0iIzAwMDIyMCIgcng9IjEiIHJ5PSIxIiB0cmFuc2Zvcm09Im1hdHJpeCgtNzQuOTY0NDMgLTI0Mi4xNzA5NCA1MS44MTE2NSAtMTYuMDM4MzkgMjEyLjggMi4xKSIvPjxlbGxpcHNlIGZpbGw9IiNmZmMwODUiIGN4PSIzMCIgY3k9IjE4MiIgcng9IjUzIiByeT0iMzAiLz48ZWxsaXBzZSBmaWxsPSIjYWFkN2ZmIiByeD0iMSIgcnk9IjEiIHRyYW5zZm9ybT0ibWF0cml4KDE0LjE5MzQgLTEuMTE3MDQgMi41MzY2MyAzMi4yMzA5OSAxODYuMiAxMjQpIi8+PGVsbGlwc2UgZmlsbD0iIzAwMGYwMCIgcng9IjEiIHJ5PSIxIiB0cmFuc2Zvcm09InJvdGF0ZSg1NS45IC04MS4xIDEyMi42KSBzY2FsZSg0NS4xMjY2NSAyMS41MzcwNikiLz48cGF0aCBmaWxsPSIjZmZhYzNmIiBkPSJNOTggMjVsMzUtNy01NyA5MXoiLz48ZWxsaXBzZSBmaWxsPSIjZWFhNjE1IiByeD0iMSIgcnk9IjEiIHRyYW5zZm9ybT0ibWF0cml4KC0xMS41NDM5IDIyLjY1NjE4IC0yNC42MDY5OSAtMTIuNTM3ODkgMTMuNCAxMTguNCkiLz48cGF0aCBmaWxsPSIjZTRhNTI4IiBkPSJNMTc2LjkgOTUuOGwtNDUgMTMuOC0xMC44LTM1LjQgNDUtMTMuOHoiLz48L2c+PC9zdmc+',
      },
      breakpointMedium: {
        name: 'HeroServiceNutrition_01--medium',
        placeholder:
          'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzNzUxIDI1MDEiPjxmaWx0ZXIgaWQ9ImIiPjxmZUdhdXNzaWFuQmx1ciBzdGREZXZpYXRpb249IjEyIiAvPjwvZmlsdGVyPjxwYXRoIGZpbGw9IiM1MTVjNDkiIGQ9Ik0wIDBoMzc1MXYyNDkwSDB6Ii8+PGcgZmlsdGVyPSJ1cmwoI2IpIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg3LjMgNy4zKSBzY2FsZSgxNC42NTIzNCkiIGZpbGwtb3BhY2l0eT0iLjUiPjxlbGxpcHNlIGZpbGw9IiNmZmM0ODQiIHJ4PSIxIiByeT0iMSIgdHJhbnNmb3JtPSJtYXRyaXgoOC43NTEzNCAtNDAuNTMzOTEgNjUuNDMyOTQgMTQuMTI3MDggMzcgMTYyLjUpIi8+PGVsbGlwc2UgZmlsbD0iI2YxZWZkYyIgcng9IjEiIHJ5PSIxIiB0cmFuc2Zvcm09Im1hdHJpeCg1Ni42MTUzNiAxLjc3OTIgLTEuMTAzOCAzNS4xMjM2IDYuNSAxNS42KSIvPjxlbGxpcHNlIGZpbGw9IiMwMDAzMzEiIHJ4PSIxIiByeT0iMSIgdHJhbnNmb3JtPSJtYXRyaXgoLTkxLjY3NjAyIC0yMzcuOTUwNjQgNDQuNjAyNjcgLTE3LjE4NDIxIDIzNS43IDU1LjMpIi8+PGVsbGlwc2UgZmlsbD0iI2FlZGFmZiIgcng9IjEiIHJ5PSIxIiB0cmFuc2Zvcm09Im1hdHJpeCgyLjE0NjE2IDMxLjIyMzM2IC0xNC40ODk0IC45OTU5NCAxODMuMiA4Ni41KSIvPjxlbGxpcHNlIGZpbGw9IiMwMDEzMDAiIHJ4PSIxIiByeT0iMSIgdHJhbnNmb3JtPSJtYXRyaXgoLTE5LjU1Nzc0IDEyLjA4Nzg1IC0yMy44NDkyNiAtMzguNTg3MyA2Ni43IDg4LjgpIi8+PHBhdGggZmlsbD0iI2Q5OWQzNCIgZD0iTTE4MiA2MUw1MyA0N2w0Ny0zOXoiLz48ZWxsaXBzZSBmaWxsPSIjZTlhNTExIiBjeD0iMTMiIGN5PSI4MyIgcng9IjI3IiByeT0iMjYiLz48ZWxsaXBzZSBmaWxsPSIjMmM3NGUxIiByeD0iMSIgcnk9IjEiIHRyYW5zZm9ybT0ibWF0cml4KC0zLjk0NDUzIC0xNi45NTA2IDM5LjAxODgzIC05LjA3OTk3IDE5Ny40IDE2MS41KSIvPjwvZz48L3N2Zz4=',
      },
      breakpointLarge: {
        name: 'HeroServiceNutrition_01--large',
        placeholder:
          'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzNzUxIDE2MDgiPjxmaWx0ZXIgaWQ9ImIiPjxmZUdhdXNzaWFuQmx1ciBzdGREZXZpYXRpb249IjEyIiAvPjwvZmlsdGVyPjxwYXRoIGZpbGw9IiM1MDU5NDEiIGQ9Ik0wIDBoMzc1MXYxNTk3SDB6Ii8+PGcgZmlsdGVyPSJ1cmwoI2IpIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg3LjMgNy4zKSBzY2FsZSgxNC42NTIzNCkiIGZpbGwtb3BhY2l0eT0iLjUiPjxlbGxpcHNlIGZpbGw9IiMwMDAwMmMiIGN4PSIyMjYiIGN5PSIyNCIgcng9IjMwIiByeT0iMTAzIi8+PGVsbGlwc2UgZmlsbD0iI2E4ZDZmZiIgY3g9IjE4MyIgY3k9IjUwIiByeD0iMTUiIHJ5PSIzMyIvPjxlbGxpcHNlIGZpbGw9IiNmOGJhODciIHJ4PSIxIiByeT0iMSIgdHJhbnNmb3JtPSJtYXRyaXgoLTQuNDMxMSAyNC45MzI0MyAtNDcuNTMyNzQgLTguNDQ3NzIgMzkgMTA4KSIvPjxlbGxpcHNlIGZpbGw9IiMwMDE2MDgiIHJ4PSIxIiByeT0iMSIgdHJhbnNmb3JtPSJtYXRyaXgoMTcuMTYzMTUgLTEwLjQ0NDUzIDI2Ljg3OTk1IDQ0LjE3MDkzIDY3LjIgNTQuNikiLz48ZWxsaXBzZSBmaWxsPSIjZWFjMDkxIiBjeD0iMTQiIGN5PSI0IiByeD0iODQiIHJ5PSIxMyIvPjxlbGxpcHNlIGZpbGw9IiNlYmE5MTkiIGN4PSIxNyIgY3k9IjQ4IiByeD0iMjMiIHJ5PSIyNiIvPjxlbGxpcHNlIGZpbGw9IiNkOTljMWQiIHJ4PSIxIiByeT0iMSIgdHJhbnNmb3JtPSJyb3RhdGUoLTExOS4zIDcxLjcgLTQyKSBzY2FsZSg0MC4xNTUyNCAzMC4xMDc3OSkiLz48ZWxsaXBzZSBmaWxsPSIjZDEzZTFlIiByeD0iMSIgcnk9IjEiIHRyYW5zZm9ybT0icm90YXRlKDYuMyAtNjgzLjIgMTM0NS43KSBzY2FsZSgyNi45MjY5IDE1LjM3OTYxKSIvPjwvZz48L3N2Zz4=',
      },
    },
  },
  textData: {
    header: messages.header,
  },
}

const benefitsText = [
  messages.benefit1,
  messages.benefit2,
  messages.benefit3,
  messages.benefit4,
]

const benefitsImage = {
  alt: messages.benefitsAlt,
  path: 'PageServiceNutrition',
  name: 'Muesli_01',
  placeholder:
    'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1NDU2IDM2MzciPjxmaWx0ZXIgaWQ9ImIiPjxmZUdhdXNzaWFuQmx1ciBzdGREZXZpYXRpb249IjEyIiAvPjwvZmlsdGVyPjxwYXRoIGZpbGw9IiNkOWNlYzIiIGQ9Ik0wIDBoNTQ1NnYzNjIzSDB6Ii8+PGcgZmlsdGVyPSJ1cmwoI2IpIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMC43IDEwLjcpIHNjYWxlKDIxLjMxMjUpIiBmaWxsLW9wYWNpdHk9Ii41Ij48ZWxsaXBzZSBmaWxsPSIjYzU2NTA1IiBjeD0iMTM1IiBjeT0iNjQiIHJ4PSIzNiIgcnk9IjM2Ii8+PGVsbGlwc2UgZmlsbD0iI2NhNTIwMCIgcng9IjEiIHJ5PSIxIiB0cmFuc2Zvcm09InJvdGF0ZSgxNjUuNyAzLjkgNTYuNykgc2NhbGUoNDEuNTA5ODEgMjguMzAwMjgpIi8+PGVsbGlwc2UgZmlsbD0iI2ZmZiIgY3g9IjUyIiBjeT0iMTUiIHJ4PSI2NSIgcnk9IjU1Ii8+PGVsbGlwc2UgZmlsbD0iI2E5MDAwMCIgY3g9IjE3OSIgY3k9IjE2OSIgcng9IjIyIiByeT0iMTkiLz48ZWxsaXBzZSBmaWxsPSIjZmZmIiByeD0iMSIgcnk9IjEiIHRyYW5zZm9ybT0ibWF0cml4KDcuNzEwMDMgLTQ2LjA1NCA4NS4yNTQzIDE0LjI3MjY2IDIyNy40IDE1KSIvPjxlbGxpcHNlIGZpbGw9IiNjOTdjMjIiIHJ4PSIxIiByeT0iMSIgdHJhbnNmb3JtPSJyb3RhdGUoLTgyLjUgMTk1IC04My41KSBzY2FsZSg1NC40NTY4NSAyMC44ODkzOSkiLz48ZWxsaXBzZSBmaWxsPSIjZTdmY2ZmIiByeD0iMSIgcnk9IjEiIHRyYW5zZm9ybT0ibWF0cml4KC0yMi4yMjAzNyAxMC4xNzMyOCAtODcuNjkzMDYgLTE5MS41MzgyNiA4Ni4yIDEwMSkiLz48ZWxsaXBzZSBmaWxsPSIjODQ1MDJhIiByeD0iMSIgcnk9IjEiIHRyYW5zZm9ybT0icm90YXRlKDE0MS45IDkxIDc3LjIpIHNjYWxlKDYuMTkzMzEgMzAuOTkyMzYpIi8+PC9nPjwvc3ZnPg==',
}

const PageServiceNutrition = ({ location }) => (
  <Layout
    hero={hero}
    pictureData={hero.pictureData}
    metaTags={metaTags}
    pathname={location.pathname}
  >
    <Content>
      <Header as="h2" type="header2" limit align="center" uppercase>
        {messages.title1}
      </Header>
      <Typography limit>{messages.paragraph1}</Typography>
      <Typography limit align="center" as="h3" type="header3">
        {messages.title2}
      </Typography>
      <Typography limit>{messages.paragraph2}</Typography>
      <Benefits>
        {benefitsText.map((text) => (
          <BenefitWrapper key={text}>
            <Benefit>{text}</Benefit>
          </BenefitWrapper>
        ))}
        <Img imageData={benefitsImage} fit="cover" />
      </Benefits>
      <Header as="h2" type="header2" limit align="center" uppercase>
        {messages.title3}
      </Header>
      <Typography limit preWrap>
        {messages.paragraph3}
      </Typography>
    </Content>
  </Layout>
)

PageServiceNutrition.propTypes = {
  location: PropTypes.object.isRequired,
}

export default PageServiceNutrition
