import styled from 'styled-components'

import Typography from '~components/Typography'
import { fluidSpacingFunc } from '~styles/helpers'

const Benefit = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.primary.contrastText};
  ${fluidSpacingFunc([{ property: 'padding', values: [4] }])};
`

export default Benefit
