import styled from 'styled-components'

import { media } from '~styles/mediaQuery'

const BenefitsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.palette.primary.main};
  ${media.mq1`
    max-width: 400px;
  `};
`

export default BenefitsWrapper
